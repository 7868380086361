<template>
  <div>
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset
        :loading="loading"
        :model.sync="model"
        @search="
          _clearPagination();
          getList();
        "
      ></search-reset>
    </l-toggle>
    <p class="tip">
      总交易笔数：
      <span class="green">{{ tradeCount }}</span
      >， 总交易金额：
      <span class="green">{{ tradeAmountTotal }}</span>
      元， 总支付货款金额：
      <span class="green">{{ goodsAmountTotal }}</span>
      元， 总寄存费金额：
      <span class="green">{{ consignFeeTotal }}</span>
      元， 总续期费金额：
      <span class="green">{{ renewalFeeTotal }}</span>
      元， 总处置金额：
      <span class="green">{{ disposalAmountTotal }}</span>
      元， 总处置收益金额：
      <span class="green">{{ disposalIncomeTotal }}</span>
      元
    </p>
    <dynamic-table
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :pagination.sync="pagination"
      @update="getList"
    ></dynamic-table>
    <confirmPaymentAccount ref="confirmPaymentAccount" @successCallback="getList"></confirmPaymentAccount>
  </div>
</template>

<script>
import LToggle from '../../components/LToggle.vue';
import DynamicForm from '../../components/DynamicForm.vue';
import SearchReset from '../../components/SearchReset.vue';
import DynamicTable from '../../components/DynamicTable.vue';
import baseMixin from '../../mixins/base-mixin';
import confirmPaymentAccount from './PaymentAccount/ConfirmPaymentAccount.vue';
import api from '../../api/CompanyAccountApi';

export default {
  name: 'PaymentAccountQuery',
  components: {
    LToggle,
    DynamicForm,
    SearchReset,
    DynamicTable,
    confirmPaymentAccount,
  },
  mixins: [baseMixin],
  data() {
    return {
      model: {},
      formItems: [
        {
          label: '客户姓名',
          type: 'text',
          name: 'userName',
          placeholder: '请输入客户姓名',
        },
        {
          label: '账号属性',
          type: 'select',
          name: 'accountAttribute',
          placeholder: '请选择账号属性',
          options: this.filterDictionary('0126'),
        },
        {
          label: '账号用途',
          type: 'select',
          name: 'accountUse',
          placeholder: '请选择账号用途',
          options: this.filterDictionary('0128'),
        },
        {
          label: '账号种类',
          type: 'select',
          name: 'accountType',
          placeholder: '请选择账号种类',
          options: this.filterDictionary('0127'),
        },
        {
          label: '账号名称',
          type: 'text',
          name: 'accountName',
          placeholder: '请输入账号名称',
        },
        {
          label: '账号',
          type: 'text',
          name: 'accountNumber',
          placeholder: '请输入账号',
        },
        {
          label: '入账状态',
          type: 'select',
          name: 'recordedStatus',
          placeholder: '请选择入账状态',
          options: this.filterDictionary('0129'),
        },
        {
          label: '交易时间',
          type: 'daterange',
          starName: 'tradeTimeBegin',
          starPlaceholder: '请输入开始日期',
          endName: 'tradeTimeEnd',
          endPlaceholder: '请输入结束日期',
        },
        {
          label: '财务确认时间',
          type: 'daterange',
          starName: 'confirmTimeBegin',
          starPlaceholder: '请输入开始日期',
          endName: 'confirmTimeEnd',
          endPlaceholder: '请输入结束日期',
        },
      ],
      columns: [
        {
          type: 'index',
          fixed: 'left',
        },
        {
          label: '账单流水号',
          prop: 'tradeSerialNumber',
          minWidth: '100',
        },
        {
          label: '客户姓名',
          prop: 'userName',
        },
        {
          label: '身份证号码',
          prop: 'identNo',
          minWidth: '100',
        },
        {
          label: '交易金额',
          prop: 'tradeAmount',
          minWidth: '60',
        },
        {
          label: '支付货款金额',
          prop: 'goodsAmount',
          minWidth: '70',
        },
        {
          label: '寄存费金额',
          prop: 'consignFee',
          minWidth: '60',
        },
        {
          label: '续期费金额',
          prop: 'renewalFee',
          minWidth: '60',
        },
        {
          label: '处置金额',
          prop: 'disposalAmount',
          minWidth: '60',
        },
        {
          label: '处置收益',
          prop: 'disposalIncome',
          minWidth: '60',
        },
        {
          label: '关联订单',
          prop: 'orderNumber',
          minWidth: '110',
        },
        {
          label: '交易时间',
          prop: 'tradeTime',
          minWidth: '60',
        },
        {
          label: '入账状态',
          prop: 'recordedStatus',
          dictTranslate: true,
          dictCode: '0129',
          minWidth: '60',
        },
        {
          label: '财务确认时间',
          prop: 'confirmTime',
          minWidth: '70',
        },
        {
          label: '入账日期',
          prop: 'recordedDate',
          minWidth: '70',
        },
        {
          label: '操作',
          prop: '_action',
          actions: [
            {
              title: '入账',
              type: 'text',
              icon: 'el-icon-check',
              event: this.recorded,
              condition: 'row.recordedStatus==1',
            },
            {
              title: '订单',
              type: 'text',
              icon: 'el-icon-view',
              id: 'see',
              condition: '!!row.orderId',
            },
          ],
          minWidth: '90',
        },
      ],
      tableData: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      tradeCount: '...',
      tradeAmountTotal: '...',
      goodsAmountTotal: '...',
      consignFeeTotal: '...',
      renewalFeeTotal: '...',
      disposalAmountTotal: '...',
      disposalIncomeTotal: '...',
    };
  },
  computed: {},
  created() {},
  methods: {
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
        };
        api
          .getAccountLedgerPageList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result.pageResult;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            this.tradeCount = res.result.tradeCount;
            this.tradeAmountTotal = res.result.tradeAmountTotal;
            this.goodsAmountTotal = res.result.goodsAmountTotal;
            this.consignFeeTotal = res.result.consignFeeTotal;
            this.renewalFeeTotal = res.result.renewalFeeTotal;
            this.disposalAmountTotal = res.result.disposalAmountTotal;
            this.disposalIncomeTotal = res.result.disposalIncomeTotal;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    recorded({ id }) {
      this.$refs.confirmPaymentAccount.open(id);
    },
  },
};
</script>

<style lang="scss">
.tip {
  padding: 0 15px;
  background: #ffffff;
}
.green {
  font-weight: bold;
  color: #009966;
}
</style>
