<template>
  <el-dialog
    title="提示"
    :visible.sync="visible"
    append-to-body
    width="400px"
    destroy-on-close
    @close="close"
  >
    <el-alert
      title="入账操作"
      type="warning"
      description="你确定操作该款项入账吗？"
      show-icon
      :closable="false"
    >
    </el-alert>
    <el-form
      ref="form"
      size="small"
      label-width="100px"
      :model="model"
      :rules="rules"
      label-suffix="："
    >
      <el-form-item label="入账日期" prop="recordedDate">
        <el-date-picker
          v-model="model.recordedDate"
          align="right"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd">
    </el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="confirm" :disabled="!model.recordedDate">
        确定
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import api from '../../../api/CompanyAccountApi';
import { mapState } from 'vuex';

export default {
  name: 'Warehousing',
  components: {},
  props: {},
  data() {
    return {
      recordedDate: '',
      id: '',
      visible: false,
      model: {
        recordedDate: '',
      },
      rules: {
        recordedDate: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('login', ['userInfo']),
  },
  created() {},
  methods: {
    open(id) {
      this.id = id;
      this.visible = true;
    },
    close() {
      this.$refs.form.resetFields();
      this.visible = false;
    },
    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.requestApi();
        }
      });
    },
    requestApi() {
      this.submitLoading = true;
      const promise = new Promise((resolve, reject) => {
        const { id, recordedDate, model } = this;
        const ext = {
          id,
          recordedDate,
          ...model,
          recordedPersonId: this.userInfo.userId,
          recordedPersonName: this.userInfo.userName,
        };
        api
          .recorded(ext)
          .then(res => {
            this.$message.success('入账成功');
            this.$emit('successCallback');
            this.close();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
      return promise;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-alert {
  margin-bottom: 25px;
}
</style>
